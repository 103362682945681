exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ekologiczna-energia-js": () => import("./../../../src/pages/ekologiczna_energia.js" /* webpackChunkName: "component---src-pages-ekologiczna-energia-js" */),
  "component---src-pages-fotowoltaika-galeria-js": () => import("./../../../src/pages/fotowoltaika_galeria.js" /* webpackChunkName: "component---src-pages-fotowoltaika-galeria-js" */),
  "component---src-pages-fotowoltaika-js": () => import("./../../../src/pages/fotowoltaika.js" /* webpackChunkName: "component---src-pages-fotowoltaika-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klimatyzacja-galeria-js": () => import("./../../../src/pages/klimatyzacja_galeria.js" /* webpackChunkName: "component---src-pages-klimatyzacja-galeria-js" */),
  "component---src-pages-klimatyzacja-js": () => import("./../../../src/pages/klimatyzacja.js" /* webpackChunkName: "component---src-pages-klimatyzacja-js" */),
  "component---src-pages-kompensacja-js": () => import("./../../../src/pages/kompensacja.js" /* webpackChunkName: "component---src-pages-kompensacja-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o_nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-pompy-ciepla-galeria-js": () => import("./../../../src/pages/pompy_ciepla_galeria.js" /* webpackChunkName: "component---src-pages-pompy-ciepla-galeria-js" */),
  "component---src-pages-pompy-ciepla-js": () => import("./../../../src/pages/pompy_ciepla.js" /* webpackChunkName: "component---src-pages-pompy-ciepla-js" */),
  "component---src-pages-produkcja-zywnosci-js": () => import("./../../../src/pages/produkcja_zywnosci.js" /* webpackChunkName: "component---src-pages-produkcja-zywnosci-js" */),
  "component---src-pages-sklep-techniczny-js": () => import("./../../../src/pages/sklep_techniczny.js" /* webpackChunkName: "component---src-pages-sklep-techniczny-js" */),
  "component---src-pages-smart-dom-js": () => import("./../../../src/pages/smart_dom.js" /* webpackChunkName: "component---src-pages-smart-dom-js" */)
}

